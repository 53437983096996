<template>

    <form-group class="file-upload" :label="label" :name="name" :tooltip="tooltip" :rules="computedRules" :missing="missing" @errors="$emit('error', $event)" v-slot="{ id, validate, reset, errors }" ref="formGroup">

        <div :class="['file-group', { 'errors': errors.length }]">

            <input :value="file" v-show="false" />

            <input type="file" :id="id" @change="emitInput($event, validate)" ref="file" :accept="extensions.map(ext => `.${ext}`).join(', ')"/>

            <div class="active-box" v-if="fileName">

                <span class="file-name" v-html="fileName"></span>

                <button-input class="reset" text="" @click="reset(); resetFile()" />

            </div>

            <template v-else>

                <div :class="['dropbox', {'large-option': largeOption}, {'dragOver': dragOver}]" :style="{ padding: `${areaSize}px` }" @click="$refs.file.click()" @drop.prevent="emitInput($event, validate)" @dragover.prevent="dragOver = true" @dragleave.prevent="dragOver = false">

                    <svg-icon class="icon" iconColor="#393939" width="24" height="24" viewBox="0 0 24 24"><upload-icon /></svg-icon>

                    <span :class="['title', {'dragOver': dragOver}]" v-if="largeOption">{{ $t('file_upload_label') }}</span>

                </div>

                <div class="upload" v-if="!largeOption">

                    <span class="title">{{ $t('import_file') }}</span>

                    <span class="drop">{{ $t('or_drop') }}</span>

                </div>

                <div class="options" v-if="displayOptions">

                    <span class="extensions">{{ $t('extensions', { types: extensions.length ? extensions.map(ext => '.' + ext).join(', ') : '.jpg, .jpeg, .png' }) }}</span>

                    <span class="max_upload">{{ $t('max_upload', { mo: max_upload }) }}</span>

                </div>

            </template>

        </div>

    </form-group>

</template>

<script>
import FormGroup from '~/components/inputs/form-group'
import SvgIcon from '~/components/svg-icon'
import { UploadIcon } from '~/components/icons/button-icons'

export default {
    components: {
        FormGroup,
        SvgIcon,
        UploadIcon
    },

    props: {
        label: {
            type: String,
            required: false
        },
        name: {
            type: String,
            required: true
        },
        value: {
            type: [String, FormData, Object],
            required: true
        },
        rules: {
            type: String,
            default: ''
        },
        tooltip: {
            type: String,
            required: false
        },
        displayOptions: {
            type: Boolean,
            default: true
        },
        extensions: {
            type: Array,
            default: () => []
        },
        image: {
            type: Boolean,
            default: true
        },
        max_upload: {
            type: Number,
            default: 30
        },
        missing: {
            type: Boolean,
            default: false
        },
        areaSize: {
            type: Number,
            default: 26
        },
        largeOption: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            fileName: '',
            file: '',
            events: ['dragenter', 'dragover', 'dragleave', 'drop'],
            dragOver: false
        }
    },

    mounted() {
        this.events.forEach(event => document.body.addEventListener(event, (e) => e.preventDefault()))
    },

    unmounted() {
        this.events.forEach(event => document.body.removeEventListener(event, (e) => e.preventDefault()))
    },

    methods: {
        async emitInput(event, validate) {

            this.dragOver = false

            const fileList = event.target.files || event.dataTransfer.files

            if (!fileList.length) return

            this.file = fileList[0]

            const { valid } = await validate(event)

            if (valid) {

                this.fileName = this.file.name

                const formData = new FormData()

                formData.append('file', this.file)

                this.$emit('input', formData)
            }
        },

        resetFile() {
            this.file = ''

            this.fileName = ''

            this.$emit('input', '')
        },

        setErrors(errors) {
            this.$refs.formGroup.setErrors(errors)
        },

        browseFile() {
            this.$refs.file.click()
        }
    },

    computed: {
        computedRules() {
            let str = this.rules

            if (this.extensions.length) {
                if (str.length) str += '|'

                str += 'ext:' + this.extensions
            }

            else if (this.image) {
                if (str.length) str += '|'

                str += 'image'
            }

            if (str.length) str += '|'

            str += 'size:' + (this.max_upload * 1000)

            return str
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(newValue) {

                if (typeof newValue === 'string') {

                    if (newValue === '') this.resetFile()

                } else if (newValue instanceof FormData) {

                    let fd = newValue.get('file')

                    this.fileName = fd.name

                } else if (typeof newValue === 'object') {

                    if (!Object.keys(newValue).length) this.resetFile()

                    if (typeof newValue['_jv'] !== 'undefined') this.fileName = newValue.name
                }
            }
        }
    }
}
</script>

<i18n>
    {
        "fr": {
            "browse_file": "Parcourir",
            "file": "Fichier : ",
            "cancel": "Annuler",
            "import_file": "Importer un fichier",
            "or_drop": "ou glisser/deposer votre fichier",
            "extensions": "Format {types} acceptés",
            "max_upload": "{mo}mo max.",
            "file_upload_label": "Importer ou glisser/déposer un fichier (fichiers pdf uniquement. 2 Mo max.)"
        }
    }
</i18n>

<style lang="scss">
    $border-dropbox: #DEE3EF;
    $upload-active: #BED000;
    $options-color: #8F94A0;
    $separator-color: #F2F2F2;
    $background-file: #EFEFEF;
    $file-name-color: #525254;
    $green: #BED000;

    .file-upload {
        input[type="file"] {
            display: none;
        }

        .label {
            color: #767676;
            font-size: 14px;
            font-weight: 400;
        }

        .file-group {
            display: flex;
            align-items: center;

            .active-box {
                display: flex;
                background: $background-file;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                padding: 4px 12px;
                padding-right: 4px;
                height: 30px;
                box-sizing: border-box;

                .file-name {
                    @include body;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 330px;
                    color: $file-name-color;
                    font-weight: normal;
                    font-size: 10.5pt;
                    font-style: normal;
                    font-weight: 700;
                }

                .reset {
                    position: relative;
                    margin-left: 8px;
                    padding: 0;
                    background: white;
                    width: 22px;
                    height: 22px;
                    min-height: inherit;
                    border-radius: 100%;

                    &:after,
                    &:before {
                        transform: translate(-50%, -50%) rotate(45deg);
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        display: flex;
                        content: '';
                        background: $file-name-color;
                        width: 12px;
                        height: 2px;
                        border-radius: 2px;
                    }

                    &:after {
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }
                }
            }

            .dropbox {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border: 1px dashed $border-dropbox;
                border-radius: 12px;
                cursor: pointer;

                .icon {
                    background-color: #BED000;
                    height: 24px;
                    width: 24px;
                    border-radius: 4px;
                }

                &.large-option {
                    width: 100%;
                    border: 2px dashed $upload-active;
                }

                &.dragOver{
                    border: 2px dashed $green;
                }

                .title {
                    max-width: 50%;
                    text-align: center;
                    color: $upload-active;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;

                    &.dragOver{
                        color: $green;
                    }
                }
            }

            .upload {
                margin-left: 24px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-right: 1px solid $separator-color;
                margin-right: 24px;
                padding-right: 24px;

                .title {
                    text-align: left;
                    color: $upload-active;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                }

                .drop {
                    color: #767676;
                    text-align: left;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                }
            }

            .options {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .extensions,
                .max_upload {
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    color: $options-color;
                }
            }

            &.errors {
                .dropbox {
                    background-color: #FBE8E8;
                    border-color: #F5A623;
                }
            }
        }
    }
</style>
