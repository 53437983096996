<template>

    <button :class="['button-input', { 'disabled': disabled, 'loading': loader }]" :style="buttonStyle" :type="type" @click.stop="buttonClick" :disabled="disabled || loader" >

        <spinner-loader v-if="loader" />

        <template v-else>

            <svg-icon class="icon img" v-if="iconImg === 'edit'" :iconColor="iconColor ? iconColor : '#BED000'" :viewbox="'0 0 24 24'" :width="'24'" :height="'24'"><edit-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'terminate'" :iconColor="iconColor ? iconColor : '#BED000'" :viewbox="'0 0 24 24'" :width="'24'" :height="'24'"><terminate-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'download'" :iconColor="iconColor ? iconColor : '#BED000'" width="24" height="24" :viewbox="'0 0 24 24'"><download-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'add_new'" :iconColor="iconColor ? iconColor : '#BED000'" :width="20" :height="20" :viewbox="'0 0 20 20'"><add-new-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'create'" :iconColor="iconColor ? iconColor : 'transaprent'" width="24" height="24" viewbox="0 0 21 20"><add-new-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'print'" :iconColor="iconColor ? iconColor : '#BED000'" :viewbox="'0 0 16 14'"><print-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'signature'" :iconStrokeColor="iconColor ? iconColor : '#FFFFFF'" :iconColor="iconColor ? iconColor : '#FFFFFF'" :viewbox="'0 0 19 17'"><signature-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'delete'" :iconColor="iconColor ? iconColor : '#525254'" :width="'24'" :height="'24'" :viewbox="'0 0 24 24'"><delete-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'debit'" :iconColor="iconColor ? iconColor : '#BED000'" viewBox="0 0 23 23"><debit-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'refresh'" :iconColor="iconColor ? iconColor : '#BED000'" :viewbox="'0 0 24 24'"><refresh-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'view'" :iconColor="iconColor ? iconColor : '#BED000'" :viewbox="'0 0 24 24'" width="24" height="24"><view-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'feather'" :iconColor="iconColor ? iconColor : '#FFAE02'" width="16" height="18" :viewbox="'0 0 16 18'"><feather-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'downloadpurple'" :iconColor="iconColor ? iconColor : '#BED000'" :viewbox="'0 0 15 18'"><download-purple-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'editstroke'" :iconStrokeColor="iconColor ? iconColor : '#BED000'" :iconColor="iconColor ? iconColor : 'none'" :viewbox="'0 0 24 24'" :width="'24'" :height="'24'"><edit-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'back'" :iconColor="iconColor ? iconColor : '#393939'" :iconStrokeColor="iconColor ? iconColor : '#393939'" :viewbox="'0 0 10 16'"><arrow-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'close'" :iconStrokeColor="iconColor ? iconColor : '#FFFFFF'" :iconColor="'#FFF'" :viewbox="'0 0 24 24'" :width="'24'" :height="'24'"><close-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'account'" :iconColor="iconColor ? iconColor : '#BED000'" :viewbox="'0 0 18 18'"><attached-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'bankaccount'" :iconColor="iconColor ? iconColor : '#BED000'" :viewbox="'0 0 18 15'"><bankaccount-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'bank'" :iconColor="iconColor ? iconColor : '#BED000'" :viewbox="'0 0 15 16'"><bank-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'play'" :iconColor="iconColor ? iconColor : '#BED000'" :width="20" :height="20" :viewbox="'0 0 24 24'"><play-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'identity'" :iconColor="iconColor ? iconColor : '#BED000'"><identity-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'paymentmethod'" :iconColor="iconColor ? iconColor : '#BED000'"  :viewbox="'0 0 18 16'"><payment-method-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'message'" :iconColor="iconColor ? iconColor : '#BED000'" viewBox="0 0 13 11" ><message-icon/></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'paperclip'" :iconColor="iconColor ? iconColor : '#BED000'" viewBox="0 0 15 14"><paper-clip-icon/></svg-icon>

            <svg-icon class="icon" v-else-if="iconImg === 'upload'" :iconColor="iconColor ? iconColor : '#BED000'"><upload-icon /></svg-icon>

            <svg-icon class="icon img up-blue" v-else-if="iconImg === 'up-blue'" :iconColor="iconColor ? iconColor : '#393939'" :viewbox="'0 0 10 16'"><arrow-icon /></svg-icon>

            <svg-icon class="icon img down-blue" v-else-if="iconImg === 'down-blue'" :iconColor="iconColor ? iconColor : '#393939'" :viewbox="'0 0 10 16'"><arrow-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'arrow-full-right'" :iconColor="iconColor ? iconColor : '#393939'" :iconStrokeColor="iconColor ? iconColor : '#393939'" :viewbox="'0 0 15 13'"><arrow-full-right /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'arrow-full-left'" style="transform: rotate(180deg)" :iconColor="iconColor ? iconColor : '#393939'" :iconStrokeColor="iconColor ? iconColor : '#393939'" :viewbox="'0 0 15 13'"><arrow-full-right /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'fees'" :iconColor="iconColor ? iconColor : '#BED000'" :viewbox="'0 0 24 24'" :width="'24'" :height="'24'"><fees-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'grid'" :width="14" :height="15" :viewBox="'0 0 14 15'" strokeWidth="2"><grid-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'list'" :width="16" :height="15" :viewBox="'0 0 16 15'" strokeWidth="2"><list-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'export'" :width="24" :height="24" :viewBox="'0 0 24 24'" strokeWidth="2"><export-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'add_new_circle'" :width="24" :height="24" :viewBox="'0 0 24 24'" :iconColor="'#BED000'" strokeWidth="2"><add-new-circle-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'folder'" :width="24" :height="24" :viewBox="'0 0 24 24'" :iconColor="iconColor ? iconColor : '#393939'" strokeWidth="2"><folder-icon /></svg-icon>

            <svg-icon class="icon img" v-else-if="iconImg === 'check'" :width="24" :height="24" :viewBox="'0 0 24 24'" :iconColor="iconColor ? iconColor : '#393939'" strokeWidth="2"><check-icon /></svg-icon>

            <div :class="['text', { 'text-no-padding': noPadding, 'icon-offset': iconImg }]" :style="textStyle" v-if="text">

                {{ text }}

                <span class="text disabled-text" v-if="disabled && disabledText">{{ `(${disabledText})` }}</span>

            </div>

            <template v-if="tooltip">

                <div class="icon-right" @click.stop="showTooltip = true">

                    <svg-icon width="18" height="18"><info-icon /></svg-icon>

                </div>

                <tooltip class="tooltip top" :text="tooltip" ref="tooltip" />

            </template>

            <template v-else-if="dropDownOptions">

                <drop-down class="select-box-input" :options="dropDownOptions" :open="!collapsed" @click-outside="toggleDropDown" @selectOption="redirectOption" />

                <arrow class="arrow" :active="collapsed" :width="12" />

            </template>

        </template>

    </button>

</template>

<script>
    import SpinnerLoader from '~/components/spinner-loader'
    import ButtonIconsMixin from '~/components/mixins/icons/button-icons'
    import Tooltip from '~/components/tooltip'
    import MessageIcon from '~/components/icons/button-icons/message-icon.vue'
    import PaperClipIcon from '~/components/icons/button-icons/paper-clip-icon.vue'
    import FeatherIcon from '~/components/icons/button-icons/feather-icon.vue'
    import DebitIcon from '~/components/icons/bank-icons/debit-icon'
    import PlayIcon from '~/components/icons/button-icons/play-icon.vue'
    import DropDown from '~/components/drop-down'
    import Arrow from '~/components/arrow'

    export default {
        name: 'ButtonInput',

        components: {
            SpinnerLoader,
            Tooltip,
            MessageIcon,
            PaperClipIcon,
            FeatherIcon,
            DebitIcon,
            DropDown,
            Arrow,
            PlayIcon
        },

        mixins: [ButtonIconsMixin],

        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            downloadFileName: {
                type: String,
                default: ''
            },
            action: {
                type: Function,
                required: false
            },
            iconImg: {
                type: String,
                required: false
            },
            iconColor: {
                type: String,
                required: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            noPadding: {
                type: Boolean,
                default: false
            },
            openExternalLink: {
                type: String,
                required: false
            },
            redirectExternalLink: {
                type: String,
                default: ''
            },
            fileId: {
                type: String,
                required: false
            },
            redirectTo: {
                type: [String, Object],
                default: ''
            },
            text: {
                type: String,
                required: false
            },
            tooltip: {
                type: String,
                required: false
            },
            type: {
                type: String,
                required: false,
                default: 'button'
            },
            disabledText: {
                type: String,
                required: false
            },
            textStyle: {
                type: Object,
                required: false
            },
            buttonStyle: {
                type: Object,
                required: false
            },
            dropDownOptions: {
                type: Array,
                required: false
            },
        },

        data() {
            return {
                active: false,
                innerLoader: false,
                showTooltip: false,
                optionSelected: '',
                collapsed: true
            }
        },

        methods: {
            async buttonClick(data) {
                try {
                    this.active = !this.active

                    this.innerLoader = true

                    if (this.redirectTo !== '') {

                        this.$router.push(this.redirectTo)
                    }

                    else if (typeof this.fileId !== 'undefined') {

                        let fileId = this.fileId;

                        if (typeof this.action !== 'undefined' && typeof this.action === 'function') {
                            fileId = await this.action.call()
                        }

                        const file = await this.$store.dispatch('file/fetchFile', fileId)

                        await this.downloadExternalFile(file.url)
                    }

                    else if (typeof this.openExternalLink !== 'undefined') {

                        window.open(this.openExternalLink, '_blank')
                    }

                    else if (this.redirectExternalLink !== '') {

                        if (this.downloadFileName !== '') {

                            await this.downloadExternalFile(this.redirectExternalLink)
                        }

                        else window.location.href = this.redirectExternalLink
                    }

                    else if (typeof this.dropDownOptions !== 'undefined') {

                        this.collapsed = !this.collapsed
                    }

                    this.$emit('click', data)

                } catch (error) {

                    throw error

                } finally {

                    this.innerLoader = false
                }
            },

            toggleDropDown() {

                this.collapsed = !this.collapsed
            },

            async downloadExternalFile(url) {
                try {

                    const file = await fetch(url)

                    const blob = await file.blob()

                    const link = document.createElement('a')

                    link.href = URL.createObjectURL(blob)

                    link.download = this.downloadFileName

                    link.click()

                    URL.revokeObjectURL(link.href)

                } catch (error) {

                    throw error
                }
            },

            redirectOption(value, text, option) {

                this.collapsed = false

                if (option.query) {

                    this.$router.push({ path: option.route_path, query: option.query })
                }

                else this.$router.push(option.route_path)
            }
        },

        computed: {
            loader() {
                return this.loading || this.innerLoader
            }
        }
    }
</script>

<style lang="scss">
    $tylto-orange: #FFA500;
    $tylto-orange-light: #FFF3DB;
    $grey: #767676;
    $grey-dark:#525254;
    $grey-darker: #393939;
    $grey-light: #E4E4E4;
    $green: #BED000;
    $green-light: #f8f7dc;
    $greener: #F7F9FF;;
    $success: #BED000;
    $success-light: #E5F9ED;
    $info-light: #F1F6FF;
    $dark-blue: #2A048C;
    $phantom: #EFEFEF;
    $lavander: #EDE8F8;
    $delete: #E57373;
    $warning: #FB5D5D;
    $delete-light: rgba(228,63,63,.16);

    .button-input {
        font-weight: 500;
        position: relative;
        border-radius: 28px;
        min-height: 44px;
        padding: 5px 21px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;

        .text {
            font-family: inherit;
            font-weight: inherit;
            font-size: inherit;
            color: inherit;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            flex-direction: column;

            &.icon-offset {
                // max-width: calc(100% - 35px);
                margin-left: 12px;
                margin-right: 6px;
            }

            &.disabled-text {
                font-size: 9pt;
            }
        }

        .icon {
            & ~ .text-no-padding {
                margin: 0;
            }

            &.down-blue {
                transform: rotate(-90deg);
            }

            &.up-blue {
                transform: rotate(90deg);
            }
        }

        .icon-right {
            max-width: 18px;
            max-height: 18px;
        }

        &.disabled {
            opacity: 0.5;
            background: $success;
            color: #393939 !important;
            flex-wrap: wrap;
        }

        &.background-transparent {
            background: transparent;
        }

        &.background-white {
            background: white;
        }

        &.background-warning {
            background: $warning;
        }

        &.background-success {
            background: $success;
        }

        &.background-green {
            background: $green
        }
        &.background-green-light {
            background: $green-light
        }

        &.background-greener {
            background: $greener;
        }

        &.background-success-light {
            background: $success-light;
        }

        &.background-info-light {
            background: $info-light;
        }

        &.background-dark-blue {
            background: $dark-blue;
        }

        &.background-phantom {
            background: $phantom;
        }

        &.background-lavander {
            background: $lavander;
        }

        &.background-delete-light {
            background: $delete-light;
        }

        &.background-delete {
            background: $delete;
        }

        &.background-purple {
            background: #BED000;
        }

        &.background-orange {
            background : $tylto-orange;
        }

        &.background-orange-light {
            background : $tylto-orange-light;
        }

        &.background-grey {
            background: $grey;
        }

        &.background-grey-dark {
            background: $grey-dark;
        }

        &.background-grey-darker {
            background: $grey-darker;
        }

        &.grey-dark-text-color {
            color: $grey-dark;
        }

        &.grey-darker-text-color {
            color: $grey-darker;
        }

        &.grey-text-color {
            color: $grey;
        }

        &.grey-light-text-color {
            color: $grey-light;
        }

        &.green-text-color {
            color: $green
        }
        &.green-light-text-color {
            color: $green-light
        }

        &.white-text-color {
            color: white;
        }

        &.orange-text-color {
            color : $tylto-orange;
        }

        &.success-text-color {
            color: #393939;
        }

        &.purple-text-color {
            color: #471BB5;
        }

        &.dark-blue-text-color {
            color: $dark-blue;
        }

        &.delete-text-color {
            color: $delete;
        }

        &.grey-light-border-color {
            border : 1px solid $grey-light
        }

        &.grey-light-border-color-square {
            border : 1px solid $grey-light;
            border-radius: 8px;
        }

        &.green-border-color {
            border: 2px solid $green
        }

        &.green-border-color-square {
            border: 1px solid $green;
            border-radius: 8px;
        }

        &.orange-border-color {

            border: 2px solid $tylto-orange!important;
        }

        &.white-border-color {
            border: 2px solid white!important;
        }

        &.green-border-bottom-color {
            border-bottom-color: $green
        }

        &.rounded-full {
            border-radius: 40px;
        }

        &.icon-reverse {
            .text {
                order: -1;
            }

            .icon {
                margin-right: 0;
                margin-left: 12px;
            }
        }

        &.action {
            color: #BED000;
            font-weight: 400;
            border-radius: 10px;
            background: rgba(50, 19, 130, 0.04);
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-align: left;

            &-delete{
                background: #FB5D5D;
                color: #FFFFFF;
            }

            & ~ .action {
                margin-top: 12px;
            }

        }

        &.select-box-input {
            width: 130px !important;
            background: #BED000;
            display: flex;
            height: 40px !important;
            padding: 10px 12px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 65px !important;

            .text {
                &.icon-offset {
                    margin-left: 0px;
                    margin-right: 8px;
                    border-right: 1px solid #39393933;
                    padding-right: 16px;
                }
            }

            .drop-down {
                z-index: 9;
                position: absolute;
                top: calc(100% + 5px);
                right: 0px;
                min-width: 100%;
                box-sizing: border-box;
                width: 270px;
                border-radius: 8px;
                border: none;
                background: var(--gnrique-white, #FFF);
                box-shadow: 0px 4px 13px 0px rgba(141, 141, 141, 0.08), 0px 7px 9px 0px rgba(141, 141, 141, 0.10);

                .drop-down-list {
                    display: flex;
                    padding: 4px 0px;
                    flex-direction: column;
                    align-items: flex-start;
                    flex: 1 0 0;
                    padding: 0px 0px;

                    .drop-down-icon {
                        margin-right: 8px;
                    }

                    .drop-down-option{
                        display: flex;
                        align-items: center;
                        padding: 10px 20px;
                        cursor: pointer;
                        width: 100%;
                        box-sizing: border-box;
                        transition: background-color .2s ease-in-out;
                        margin: 0;
                        border: none;
                        color: var(--gris-gris-fonc, #393939);
                        font-size: 11pt;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;

                        &:hover {
                            background-color: #F2F2F2;
                        }
                    }

                    .no-options {
                        display: flex;
                        align-items: center;
                        padding: 10px 20px;
                        cursor: pointer;
                        width: 100%;
                        box-sizing: border-box;
                        transition: background-color .2s ease-in-out;
                        margin: 0;
                        border: none;
                        color: var(--gris-gris-fonc, #393939);
                        font-size: 11pt;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }
            }

            &.collapse-top {

                .drop-down {
                    top: calc(-100% - 50px);
                    transform-origin: bottom;
                }
            }
        }
    }
</style>
