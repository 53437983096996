import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a014dd38 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _c6af0d42 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _ae96b53e = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _e8d49c1a = () => interopDefault(import('../pages/oskar-courses/index.vue' /* webpackChunkName: "pages/oskar-courses/index" */))
const _7ab87fae = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _1d94d158 = () => interopDefault(import('../pages/unauthenticated-signing-page/index.vue' /* webpackChunkName: "pages/unauthenticated-signing-page/index" */))
const _6a213005 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _2715d91e = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _589f38ef = () => interopDefault(import('../pages/auth/renew-password.vue' /* webpackChunkName: "pages/auth/renew-password" */))
const _1adf316d = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _64112328 = () => interopDefault(import('../pages/auth/switch-account.vue' /* webpackChunkName: "pages/auth/switch-account" */))
const _11f3ff2e = () => interopDefault(import('../pages/dashboard/bank-accounts.vue' /* webpackChunkName: "pages/dashboard/bank-accounts" */))
const _6fa75425 = () => interopDefault(import('../pages/dashboard/landlords.vue' /* webpackChunkName: "pages/dashboard/landlords" */))
const _0df7687a = () => interopDefault(import('../pages/dashboard/notifications.vue' /* webpackChunkName: "pages/dashboard/notifications" */))
const _8ea768de = () => interopDefault(import('../pages/dashboard/properties.vue' /* webpackChunkName: "pages/dashboard/properties" */))
const _2543e939 = () => interopDefault(import('../pages/dashboard/suppliers.vue' /* webpackChunkName: "pages/dashboard/suppliers" */))
const _2d3068ca = () => interopDefault(import('../pages/dashboard/tenants.vue' /* webpackChunkName: "pages/dashboard/tenants" */))
const _6da452bc = () => interopDefault(import('../pages/dashboard/unpaid-costs.vue' /* webpackChunkName: "pages/dashboard/unpaid-costs" */))
const _e70d669a = () => interopDefault(import('../pages/dashboard/unpaid-rent.vue' /* webpackChunkName: "pages/dashboard/unpaid-rent" */))
const _c4ab0984 = () => interopDefault(import('../pages/error/404.vue' /* webpackChunkName: "pages/error/404" */))
const _a4fda90e = () => interopDefault(import('../pages/onboarding/confirm_invitation.vue' /* webpackChunkName: "pages/onboarding/confirm_invitation" */))
const _002ec924 = () => interopDefault(import('../pages/onboarding/demo.vue' /* webpackChunkName: "pages/onboarding/demo" */))
const _8b7c2cee = () => interopDefault(import('../pages/settings/company-details.vue' /* webpackChunkName: "pages/settings/company-details" */))
const _1dcdae10 = () => interopDefault(import('../pages/swan/callback.vue' /* webpackChunkName: "pages/swan/callback" */))
const _e5bbac3a = () => interopDefault(import('../pages/swan/consent-callback.vue' /* webpackChunkName: "pages/swan/consent-callback" */))
const _3a0329e8 = () => interopDefault(import('../pages/dashboard/accounting/listing.vue' /* webpackChunkName: "pages/dashboard/accounting/listing" */))
const _04371c12 = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-accounts.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-accounts" */))
const _1674d58f = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-entries.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-entries" */))
const _7598799d = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-entry-sets.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-entry-sets" */))
const _d95a39a6 = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-journals.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-journals" */))
const _51577339 = () => interopDefault(import('../pages/viewer/CGU/cgu.vue' /* webpackChunkName: "pages/viewer/CGU/cgu" */))
const _08fa6b99 = () => interopDefault(import('../pages/workflow/account-statement/create.vue' /* webpackChunkName: "pages/workflow/account-statement/create" */))
const _28ebf03e = () => interopDefault(import('../pages/workflow/landlord/create.vue' /* webpackChunkName: "pages/workflow/landlord/create" */))
const _00105aae = () => interopDefault(import('../pages/workflow/property/create.vue' /* webpackChunkName: "pages/workflow/property/create" */))
const _5c016652 = () => interopDefault(import('../pages/workflow/supplier/create.vue' /* webpackChunkName: "pages/workflow/supplier/create" */))
const _159e8b74 = () => interopDefault(import('../pages/workflow/tenant/create.vue' /* webpackChunkName: "pages/workflow/tenant/create" */))
const _a1c65124 = () => interopDefault(import('../pages/dashboard/accounting/accounting-entry-set/_accountingEntrySetId/index.vue' /* webpackChunkName: "pages/dashboard/accounting/accounting-entry-set/_accountingEntrySetId/index" */))
const _1a01d0ac = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/index.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/index" */))
const _47e25b80 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/index.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/index" */))
const _092018ed = () => interopDefault(import('../pages/viewer/file/_fileId.vue' /* webpackChunkName: "pages/viewer/file/_fileId" */))
const _7348dad5 = () => interopDefault(import('../pages/viewer/inventory/_inventoryId.vue' /* webpackChunkName: "pages/viewer/inventory/_inventoryId" */))
const _29ddf626 = () => interopDefault(import('../pages/viewer/management-report/_managementReportId.vue' /* webpackChunkName: "pages/viewer/management-report/_managementReportId" */))
const _4d06ca55 = () => interopDefault(import('../pages/viewer/mandate/_mandateId.vue' /* webpackChunkName: "pages/viewer/mandate/_mandateId" */))
const _389bd815 = () => interopDefault(import('../pages/viewer/payment/_paymentId.vue' /* webpackChunkName: "pages/viewer/payment/_paymentId" */))
const _04dda1b2 = () => interopDefault(import('../pages/viewer/rent/_rentId.vue' /* webpackChunkName: "pages/viewer/rent/_rentId" */))
const _c3bf0f86 = () => interopDefault(import('../pages/viewer/rental/_rentalId.vue' /* webpackChunkName: "pages/viewer/rental/_rentalId" */))
const _074ba692 = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/documents.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/documents" */))
const _5e776946 = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/informations.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/informations" */))
const _3c13574b = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/management-reports.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/management-reports" */))
const _9b674d6e = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/properties.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/properties" */))
const _1115be26 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/documents.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/documents" */))
const _05721b49 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/informations.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/informations" */))
const _d470c74e = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/payment-methods.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/payment-methods" */))
const _42c96d3d = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/rentals.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/rentals" */))
const _d4469c70 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/rents.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/rents" */))
const _0d9d8e01 = () => interopDefault(import('../pages/workflow/property/_propertyId/cost/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/cost/create" */))
const _e89823c8 = () => interopDefault(import('../pages/workflow/property/_propertyId/inventory/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/inventory/create" */))
const _6d6b4be9 = () => interopDefault(import('../pages/workflow/property/_propertyId/mandate/create.old.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/mandate/create.old" */))
const _086683f8 = () => interopDefault(import('../pages/workflow/property/_propertyId/mandate/form.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/mandate/form" */))
const _538071d6 = () => interopDefault(import('../pages/workflow/property/_propertyId/rent/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/rent/create" */))
const _b61174ec = () => interopDefault(import('../pages/workflow/property/_propertyId/rental/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/rental/create" */))
const _754742a8 = () => interopDefault(import('../pages/workflow/property/_propertyId/tenant-cost/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/tenant-cost/create" */))
const _71fd6605 = () => interopDefault(import('../pages/viewer/property/_propertyId/cost/_costId.vue' /* webpackChunkName: "pages/viewer/property/_propertyId/cost/_costId" */))
const _225d7965 = () => interopDefault(import('../pages/workflow/property/_propertyId/rental/_rentalId/replace-tenant/index.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/rental/_rentalId/replace-tenant/index" */))
const _01134391 = () => interopDefault(import('../pages/landlord/_landlordId/index.vue' /* webpackChunkName: "pages/landlord/_landlordId/index" */))
const _0a52a98a = () => interopDefault(import('../pages/property/_propertyId/index.vue' /* webpackChunkName: "pages/property/_propertyId/index" */))
const _2a924569 = () => interopDefault(import('../pages/supplier/_supplierId/index.vue' /* webpackChunkName: "pages/supplier/_supplierId/index" */))
const _07af7fa5 = () => interopDefault(import('../pages/tenant/_tenantId/index.vue' /* webpackChunkName: "pages/tenant/_tenantId/index" */))
const _50ef1ef7 = () => interopDefault(import('../pages/landlord/_landlordId/documents.vue' /* webpackChunkName: "pages/landlord/_landlordId/documents" */))
const _36fbbcd8 = () => interopDefault(import('../pages/landlord/_landlordId/informations.vue' /* webpackChunkName: "pages/landlord/_landlordId/informations" */))
const _305efef4 = () => interopDefault(import('../pages/landlord/_landlordId/management-reports.vue' /* webpackChunkName: "pages/landlord/_landlordId/management-reports" */))
const _769421e8 = () => interopDefault(import('../pages/landlord/_landlordId/payouts.vue' /* webpackChunkName: "pages/landlord/_landlordId/payouts" */))
const _346d3acc = () => interopDefault(import('../pages/landlord/_landlordId/update.vue' /* webpackChunkName: "pages/landlord/_landlordId/update" */))
const _2e1277ec = () => interopDefault(import('../pages/property/_propertyId/contracts.vue' /* webpackChunkName: "pages/property/_propertyId/contracts" */))
const _1becf2af = () => interopDefault(import('../pages/property/_propertyId/costs.vue' /* webpackChunkName: "pages/property/_propertyId/costs" */))
const _6602a1a1 = () => interopDefault(import('../pages/property/_propertyId/documents.vue' /* webpackChunkName: "pages/property/_propertyId/documents" */))
const _96b6ea7a = () => interopDefault(import('../pages/property/_propertyId/rents.vue' /* webpackChunkName: "pages/property/_propertyId/rents" */))
const _2472f030 = () => interopDefault(import('../pages/property/_propertyId/update.vue' /* webpackChunkName: "pages/property/_propertyId/update" */))
const _90711000 = () => interopDefault(import('../pages/supplier/_supplierId/informations.vue' /* webpackChunkName: "pages/supplier/_supplierId/informations" */))
const _27aac87c = () => interopDefault(import('../pages/supplier/_supplierId/update.vue' /* webpackChunkName: "pages/supplier/_supplierId/update" */))
const _0fb19dea = () => interopDefault(import('../pages/tenant/_tenantId/documents.vue' /* webpackChunkName: "pages/tenant/_tenantId/documents" */))
const _a608fb78 = () => interopDefault(import('../pages/tenant/_tenantId/informations.vue' /* webpackChunkName: "pages/tenant/_tenantId/informations" */))
const _046e8f7e = () => interopDefault(import('../pages/tenant/_tenantId/payment-methods.vue' /* webpackChunkName: "pages/tenant/_tenantId/payment-methods" */))
const _9a96adf4 = () => interopDefault(import('../pages/tenant/_tenantId/update.vue' /* webpackChunkName: "pages/tenant/_tenantId/update" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dashboard",
    component: _a014dd38,
    name: "dashboard___fr"
  }, {
    path: "/en",
    component: _c6af0d42,
    name: "index___en"
  }, {
    path: "/onboarding",
    component: _ae96b53e,
    name: "onboarding___fr"
  }, {
    path: "/oskar-courses",
    component: _e8d49c1a,
    name: "oskar-courses___fr"
  }, {
    path: "/settings",
    component: _7ab87fae,
    name: "settings___fr"
  }, {
    path: "/unauthenticated-signing-page",
    component: _1d94d158,
    name: "unauthenticated-signing-page___fr"
  }, {
    path: "/auth/login",
    component: _6a213005,
    name: "auth-login___fr"
  }, {
    path: "/auth/logout",
    component: _2715d91e,
    name: "auth-logout___fr"
  }, {
    path: "/auth/renew-password",
    component: _589f38ef,
    name: "auth-renew-password___fr"
  }, {
    path: "/auth/reset-password",
    component: _1adf316d,
    name: "auth-reset-password___fr"
  }, {
    path: "/auth/switch-account",
    component: _64112328,
    name: "auth-switch-account___fr"
  }, {
    path: "/dashboard/bank-accounts",
    component: _11f3ff2e,
    name: "dashboard-bank-accounts___fr"
  }, {
    path: "/dashboard/landlords",
    component: _6fa75425,
    name: "dashboard-landlords___fr"
  }, {
    path: "/dashboard/notifications",
    component: _0df7687a,
    name: "dashboard-notifications___fr"
  }, {
    path: "/dashboard/properties",
    component: _8ea768de,
    name: "dashboard-properties___fr"
  }, {
    path: "/dashboard/suppliers",
    component: _2543e939,
    name: "dashboard-suppliers___fr"
  }, {
    path: "/dashboard/tenants",
    component: _2d3068ca,
    name: "dashboard-tenants___fr"
  }, {
    path: "/dashboard/unpaid-costs",
    component: _6da452bc,
    name: "dashboard-unpaid-costs___fr"
  }, {
    path: "/dashboard/unpaid-rent",
    component: _e70d669a,
    name: "dashboard-unpaid-rent___fr"
  }, {
    path: "/en/dashboard",
    component: _a014dd38,
    name: "dashboard___en"
  }, {
    path: "/en/onboarding",
    component: _ae96b53e,
    name: "onboarding___en"
  }, {
    path: "/en/oskar-courses",
    component: _e8d49c1a,
    name: "oskar-courses___en"
  }, {
    path: "/en/settings",
    component: _7ab87fae,
    name: "settings___en"
  }, {
    path: "/en/unauthenticated-signing-page",
    component: _1d94d158,
    name: "unauthenticated-signing-page___en"
  }, {
    path: "/error/404",
    component: _c4ab0984,
    name: "error-404___fr"
  }, {
    path: "/onboarding/confirm_invitation",
    component: _a4fda90e,
    name: "onboarding-confirm_invitation___fr"
  }, {
    path: "/onboarding/demo",
    component: _002ec924,
    name: "onboarding-demo___fr"
  }, {
    path: "/settings/company-details",
    component: _8b7c2cee,
    name: "settings-company-details___fr"
  }, {
    path: "/swan/callback",
    component: _1dcdae10,
    name: "swan-callback___fr"
  }, {
    path: "/swan/consent-callback",
    component: _e5bbac3a,
    name: "swan-consent-callback___fr"
  }, {
    path: "/dashboard/accounting/listing",
    component: _3a0329e8,
    name: "dashboard-accounting-listing___fr",
    children: [{
      path: "accounting-accounts",
      component: _04371c12,
      name: "dashboard-accounting-listing-accounting-accounts___fr"
    }, {
      path: "accounting-entries",
      component: _1674d58f,
      name: "dashboard-accounting-listing-accounting-entries___fr"
    }, {
      path: "accounting-entry-sets",
      component: _7598799d,
      name: "dashboard-accounting-listing-accounting-entry-sets___fr"
    }, {
      path: "accounting-journals",
      component: _d95a39a6,
      name: "dashboard-accounting-listing-accounting-journals___fr"
    }]
  }, {
    path: "/en/auth/login",
    component: _6a213005,
    name: "auth-login___en"
  }, {
    path: "/en/auth/logout",
    component: _2715d91e,
    name: "auth-logout___en"
  }, {
    path: "/en/auth/renew-password",
    component: _589f38ef,
    name: "auth-renew-password___en"
  }, {
    path: "/en/auth/reset-password",
    component: _1adf316d,
    name: "auth-reset-password___en"
  }, {
    path: "/en/auth/switch-account",
    component: _64112328,
    name: "auth-switch-account___en"
  }, {
    path: "/en/dashboard/bank-accounts",
    component: _11f3ff2e,
    name: "dashboard-bank-accounts___en"
  }, {
    path: "/en/dashboard/landlords",
    component: _6fa75425,
    name: "dashboard-landlords___en"
  }, {
    path: "/en/dashboard/notifications",
    component: _0df7687a,
    name: "dashboard-notifications___en"
  }, {
    path: "/en/dashboard/properties",
    component: _8ea768de,
    name: "dashboard-properties___en"
  }, {
    path: "/en/dashboard/suppliers",
    component: _2543e939,
    name: "dashboard-suppliers___en"
  }, {
    path: "/en/dashboard/tenants",
    component: _2d3068ca,
    name: "dashboard-tenants___en"
  }, {
    path: "/en/dashboard/unpaid-costs",
    component: _6da452bc,
    name: "dashboard-unpaid-costs___en"
  }, {
    path: "/en/dashboard/unpaid-rent",
    component: _e70d669a,
    name: "dashboard-unpaid-rent___en"
  }, {
    path: "/en/error/404",
    component: _c4ab0984,
    name: "error-404___en"
  }, {
    path: "/en/onboarding/confirm_invitation",
    component: _a4fda90e,
    name: "onboarding-confirm_invitation___en"
  }, {
    path: "/en/onboarding/demo",
    component: _002ec924,
    name: "onboarding-demo___en"
  }, {
    path: "/en/settings/company-details",
    component: _8b7c2cee,
    name: "settings-company-details___en"
  }, {
    path: "/en/swan/callback",
    component: _1dcdae10,
    name: "swan-callback___en"
  }, {
    path: "/en/swan/consent-callback",
    component: _e5bbac3a,
    name: "swan-consent-callback___en"
  }, {
    path: "/viewer/CGU/cgu",
    component: _51577339,
    name: "viewer-CGU-cgu___fr"
  }, {
    path: "/workflow/account-statement/create",
    component: _08fa6b99,
    name: "workflow-account-statement-create___fr"
  }, {
    path: "/workflow/landlord/create",
    component: _28ebf03e,
    name: "workflow-landlord-create___fr"
  }, {
    path: "/workflow/property/create",
    component: _00105aae,
    name: "workflow-property-create___fr"
  }, {
    path: "/workflow/supplier/create",
    component: _5c016652,
    name: "workflow-supplier-create___fr"
  }, {
    path: "/workflow/tenant/create",
    component: _159e8b74,
    name: "workflow-tenant-create___fr"
  }, {
    path: "/en/dashboard/accounting/listing",
    component: _3a0329e8,
    name: "dashboard-accounting-listing___en",
    children: [{
      path: "accounting-accounts",
      component: _04371c12,
      name: "dashboard-accounting-listing-accounting-accounts___en"
    }, {
      path: "accounting-entries",
      component: _1674d58f,
      name: "dashboard-accounting-listing-accounting-entries___en"
    }, {
      path: "accounting-entry-sets",
      component: _7598799d,
      name: "dashboard-accounting-listing-accounting-entry-sets___en"
    }, {
      path: "accounting-journals",
      component: _d95a39a6,
      name: "dashboard-accounting-listing-accounting-journals___en"
    }]
  }, {
    path: "/en/viewer/CGU/cgu",
    component: _51577339,
    name: "viewer-CGU-cgu___en"
  }, {
    path: "/en/workflow/account-statement/create",
    component: _08fa6b99,
    name: "workflow-account-statement-create___en"
  }, {
    path: "/en/workflow/landlord/create",
    component: _28ebf03e,
    name: "workflow-landlord-create___en"
  }, {
    path: "/en/workflow/property/create",
    component: _00105aae,
    name: "workflow-property-create___en"
  }, {
    path: "/en/workflow/supplier/create",
    component: _5c016652,
    name: "workflow-supplier-create___en"
  }, {
    path: "/en/workflow/tenant/create",
    component: _159e8b74,
    name: "workflow-tenant-create___en"
  }, {
    path: "/en/dashboard/accounting/accounting-entry-set/:accountingEntrySetId",
    component: _a1c65124,
    name: "dashboard-accounting-accounting-entry-set-accountingEntrySetId___en"
  }, {
    path: "/dashboard/accounting/accounting-entry-set/:accountingEntrySetId",
    component: _a1c65124,
    name: "dashboard-accounting-accounting-entry-set-accountingEntrySetId___fr"
  }, {
    path: "/en/dashboard/landlord/:landlordId",
    component: _1a01d0ac,
    name: "dashboard-landlord-landlordId___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId",
    component: _47e25b80,
    name: "dashboard-tenant-tenantId___en"
  }, {
    path: "/en/viewer/file/:fileId?",
    component: _092018ed,
    name: "viewer-file-fileId___en"
  }, {
    path: "/en/viewer/inventory/:inventoryId?",
    component: _7348dad5,
    name: "viewer-inventory-inventoryId___en"
  }, {
    path: "/en/viewer/management-report/:managementReportId?",
    component: _29ddf626,
    name: "viewer-management-report-managementReportId___en"
  }, {
    path: "/en/viewer/mandate/:mandateId?",
    component: _4d06ca55,
    name: "viewer-mandate-mandateId___en"
  }, {
    path: "/en/viewer/payment/:paymentId?",
    component: _389bd815,
    name: "viewer-payment-paymentId___en"
  }, {
    path: "/en/viewer/rent/:rentId?",
    component: _04dda1b2,
    name: "viewer-rent-rentId___en"
  }, {
    path: "/en/viewer/rental/:rentalId?",
    component: _c3bf0f86,
    name: "viewer-rental-rentalId___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/documents",
    component: _074ba692,
    name: "dashboard-landlord-landlordId-documents___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/informations",
    component: _5e776946,
    name: "dashboard-landlord-landlordId-informations___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/management-reports",
    component: _3c13574b,
    name: "dashboard-landlord-landlordId-management-reports___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/properties",
    component: _9b674d6e,
    name: "dashboard-landlord-landlordId-properties___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/documents",
    component: _1115be26,
    name: "dashboard-tenant-tenantId-documents___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/informations",
    component: _05721b49,
    name: "dashboard-tenant-tenantId-informations___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/payment-methods",
    component: _d470c74e,
    name: "dashboard-tenant-tenantId-payment-methods___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/rentals",
    component: _42c96d3d,
    name: "dashboard-tenant-tenantId-rentals___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/rents",
    component: _d4469c70,
    name: "dashboard-tenant-tenantId-rents___en"
  }, {
    path: "/en/workflow/property/:propertyId?/cost/create",
    component: _0d9d8e01,
    name: "workflow-property-propertyId-cost-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/inventory/create",
    component: _e89823c8,
    name: "workflow-property-propertyId-inventory-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/mandate/create.old",
    component: _6d6b4be9,
    name: "workflow-property-propertyId-mandate-create.old___en"
  }, {
    path: "/en/workflow/property/:propertyId?/mandate/form",
    component: _086683f8,
    name: "workflow-property-propertyId-mandate-form___en"
  }, {
    path: "/en/workflow/property/:propertyId?/rent/create",
    component: _538071d6,
    name: "workflow-property-propertyId-rent-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/rental/create",
    component: _b61174ec,
    name: "workflow-property-propertyId-rental-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/tenant-cost/create",
    component: _754742a8,
    name: "workflow-property-propertyId-tenant-cost-create___en"
  }, {
    path: "/en/viewer/property/:propertyId?/cost/:costId?",
    component: _71fd6605,
    name: "viewer-property-propertyId-cost-costId___en"
  }, {
    path: "/en/workflow/property/:propertyId?/rental/:rentalId?/replace-tenant",
    component: _225d7965,
    name: "workflow-property-propertyId-rental-rentalId-replace-tenant___en"
  }, {
    path: "/dashboard/landlord/:landlordId",
    component: _1a01d0ac,
    name: "dashboard-landlord-landlordId___fr"
  }, {
    path: "/dashboard/tenant/:tenantId",
    component: _47e25b80,
    name: "dashboard-tenant-tenantId___fr"
  }, {
    path: "/en/landlord/:landlordId",
    component: _01134391,
    name: "landlord-landlordId___en"
  }, {
    path: "/en/property/:propertyId",
    component: _0a52a98a,
    name: "property-propertyId___en"
  }, {
    path: "/en/supplier/:supplierId",
    component: _2a924569,
    name: "supplier-supplierId___en"
  }, {
    path: "/en/tenant/:tenantId",
    component: _07af7fa5,
    name: "tenant-tenantId___en"
  }, {
    path: "/viewer/file/:fileId?",
    component: _092018ed,
    name: "viewer-file-fileId___fr"
  }, {
    path: "/viewer/inventory/:inventoryId?",
    component: _7348dad5,
    name: "viewer-inventory-inventoryId___fr"
  }, {
    path: "/viewer/management-report/:managementReportId?",
    component: _29ddf626,
    name: "viewer-management-report-managementReportId___fr"
  }, {
    path: "/viewer/mandate/:mandateId?",
    component: _4d06ca55,
    name: "viewer-mandate-mandateId___fr"
  }, {
    path: "/viewer/payment/:paymentId?",
    component: _389bd815,
    name: "viewer-payment-paymentId___fr"
  }, {
    path: "/viewer/rent/:rentId?",
    component: _04dda1b2,
    name: "viewer-rent-rentId___fr"
  }, {
    path: "/viewer/rental/:rentalId?",
    component: _c3bf0f86,
    name: "viewer-rental-rentalId___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/documents",
    component: _074ba692,
    name: "dashboard-landlord-landlordId-documents___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/informations",
    component: _5e776946,
    name: "dashboard-landlord-landlordId-informations___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/management-reports",
    component: _3c13574b,
    name: "dashboard-landlord-landlordId-management-reports___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/properties",
    component: _9b674d6e,
    name: "dashboard-landlord-landlordId-properties___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/documents",
    component: _1115be26,
    name: "dashboard-tenant-tenantId-documents___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/informations",
    component: _05721b49,
    name: "dashboard-tenant-tenantId-informations___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/payment-methods",
    component: _d470c74e,
    name: "dashboard-tenant-tenantId-payment-methods___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/rentals",
    component: _42c96d3d,
    name: "dashboard-tenant-tenantId-rentals___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/rents",
    component: _d4469c70,
    name: "dashboard-tenant-tenantId-rents___fr"
  }, {
    path: "/en/landlord/:landlordId?/documents",
    component: _50ef1ef7,
    name: "landlord-landlordId-documents___en"
  }, {
    path: "/en/landlord/:landlordId?/informations",
    component: _36fbbcd8,
    name: "landlord-landlordId-informations___en"
  }, {
    path: "/en/landlord/:landlordId?/management-reports",
    component: _305efef4,
    name: "landlord-landlordId-management-reports___en"
  }, {
    path: "/en/landlord/:landlordId?/payouts",
    component: _769421e8,
    name: "landlord-landlordId-payouts___en"
  }, {
    path: "/en/landlord/:landlordId?/update",
    component: _346d3acc,
    name: "landlord-landlordId-update___en"
  }, {
    path: "/en/property/:propertyId?/contracts",
    component: _2e1277ec,
    name: "property-propertyId-contracts___en"
  }, {
    path: "/en/property/:propertyId?/costs",
    component: _1becf2af,
    name: "property-propertyId-costs___en"
  }, {
    path: "/en/property/:propertyId?/documents",
    component: _6602a1a1,
    name: "property-propertyId-documents___en"
  }, {
    path: "/en/property/:propertyId?/rents",
    component: _96b6ea7a,
    name: "property-propertyId-rents___en"
  }, {
    path: "/en/property/:propertyId?/update",
    component: _2472f030,
    name: "property-propertyId-update___en"
  }, {
    path: "/en/supplier/:supplierId?/informations",
    component: _90711000,
    name: "supplier-supplierId-informations___en"
  }, {
    path: "/en/supplier/:supplierId?/update",
    component: _27aac87c,
    name: "supplier-supplierId-update___en"
  }, {
    path: "/en/tenant/:tenantId?/documents",
    component: _0fb19dea,
    name: "tenant-tenantId-documents___en"
  }, {
    path: "/en/tenant/:tenantId?/informations",
    component: _a608fb78,
    name: "tenant-tenantId-informations___en"
  }, {
    path: "/en/tenant/:tenantId?/payment-methods",
    component: _046e8f7e,
    name: "tenant-tenantId-payment-methods___en"
  }, {
    path: "/en/tenant/:tenantId?/update",
    component: _9a96adf4,
    name: "tenant-tenantId-update___en"
  }, {
    path: "/workflow/property/:propertyId?/cost/create",
    component: _0d9d8e01,
    name: "workflow-property-propertyId-cost-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/inventory/create",
    component: _e89823c8,
    name: "workflow-property-propertyId-inventory-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/mandate/create.old",
    component: _6d6b4be9,
    name: "workflow-property-propertyId-mandate-create.old___fr"
  }, {
    path: "/workflow/property/:propertyId?/mandate/form",
    component: _086683f8,
    name: "workflow-property-propertyId-mandate-form___fr"
  }, {
    path: "/workflow/property/:propertyId?/rent/create",
    component: _538071d6,
    name: "workflow-property-propertyId-rent-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/rental/create",
    component: _b61174ec,
    name: "workflow-property-propertyId-rental-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/tenant-cost/create",
    component: _754742a8,
    name: "workflow-property-propertyId-tenant-cost-create___fr"
  }, {
    path: "/viewer/property/:propertyId?/cost/:costId?",
    component: _71fd6605,
    name: "viewer-property-propertyId-cost-costId___fr"
  }, {
    path: "/workflow/property/:propertyId?/rental/:rentalId?/replace-tenant",
    component: _225d7965,
    name: "workflow-property-propertyId-rental-rentalId-replace-tenant___fr"
  }, {
    path: "/landlord/:landlordId",
    component: _01134391,
    name: "landlord-landlordId___fr"
  }, {
    path: "/property/:propertyId",
    component: _0a52a98a,
    name: "property-propertyId___fr"
  }, {
    path: "/supplier/:supplierId",
    component: _2a924569,
    name: "supplier-supplierId___fr"
  }, {
    path: "/tenant/:tenantId",
    component: _07af7fa5,
    name: "tenant-tenantId___fr"
  }, {
    path: "/landlord/:landlordId?/documents",
    component: _50ef1ef7,
    name: "landlord-landlordId-documents___fr"
  }, {
    path: "/landlord/:landlordId?/informations",
    component: _36fbbcd8,
    name: "landlord-landlordId-informations___fr"
  }, {
    path: "/landlord/:landlordId?/management-reports",
    component: _305efef4,
    name: "landlord-landlordId-management-reports___fr"
  }, {
    path: "/landlord/:landlordId?/payouts",
    component: _769421e8,
    name: "landlord-landlordId-payouts___fr"
  }, {
    path: "/landlord/:landlordId?/update",
    component: _346d3acc,
    name: "landlord-landlordId-update___fr"
  }, {
    path: "/property/:propertyId?/contracts",
    component: _2e1277ec,
    name: "property-propertyId-contracts___fr"
  }, {
    path: "/property/:propertyId?/costs",
    component: _1becf2af,
    name: "property-propertyId-costs___fr"
  }, {
    path: "/property/:propertyId?/documents",
    component: _6602a1a1,
    name: "property-propertyId-documents___fr"
  }, {
    path: "/property/:propertyId?/rents",
    component: _96b6ea7a,
    name: "property-propertyId-rents___fr"
  }, {
    path: "/property/:propertyId?/update",
    component: _2472f030,
    name: "property-propertyId-update___fr"
  }, {
    path: "/supplier/:supplierId?/informations",
    component: _90711000,
    name: "supplier-supplierId-informations___fr"
  }, {
    path: "/supplier/:supplierId?/update",
    component: _27aac87c,
    name: "supplier-supplierId-update___fr"
  }, {
    path: "/tenant/:tenantId?/documents",
    component: _0fb19dea,
    name: "tenant-tenantId-documents___fr"
  }, {
    path: "/tenant/:tenantId?/informations",
    component: _a608fb78,
    name: "tenant-tenantId-informations___fr"
  }, {
    path: "/tenant/:tenantId?/payment-methods",
    component: _046e8f7e,
    name: "tenant-tenantId-payment-methods___fr"
  }, {
    path: "/tenant/:tenantId?/update",
    component: _9a96adf4,
    name: "tenant-tenantId-update___fr"
  }, {
    path: "/",
    component: _c6af0d42,
    name: "index___fr"
  }],

  parseQuery: function(queryString) {
            return require('qs').parse(queryString)
        },
  stringifyQuery: function(object) {
            var queryString = require('qs').stringify(object)
            return queryString ? '?' + queryString : ''
        },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
