import defaultState from './default'

export default {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setAmountDue(state, value) {
        state.amount_due = value
    },

    setAmountPaid(state, value) {
        state.amount_paid = value
    },

    setRefundAmount(state, value) {
        state.refund_amount = value
    },

    setRentableType(state, value) {
        state.rentable_type = value
    },

    setId(state, value) {
        state.id = value
    },

    setItems(state, value) {
        state.items = value
    },

    setPaymentDate(state, value) {
        state.payment_date = value
    },

    setPaymentDueDate(state, value) {
        state.payment_due_date = value
    },

    setPayoutDate(state, value) {
        state.payout_date = value
    },

    setPeriodEndDate(state, value) {
        state.period_end_date = value
    },

    setPeriodStartDate(state, value) {
        state.period_start_date = value
    },

    setReference(state, value) {
        state.reference = value
    },

    setStatus(state, value) {
        state.status = value
    },

    setTotalAmount(state, value) {
        state.total_amount = value
    },

    setVatAmount(state, value) {
        state.vat_amount = value
    },

    setInvoiceNumber(state, value) {
        state.invoice_number = value
    },

    setDate(state, value) {
        state.date = value
    },

    setRelatedRental(state, value) {
        state.relationships.rental = value
    },

    setRelatedRentNotice(state, value) {
        state.relationships.rent_notice = value
    },

    setRelatedRentReceipt(state, value) {
        state.relationships.rent_receipt = value
    },

    setRelatedPayments(state, value) {
        state.relationships.payments = value
    },

    setRelatedTenants(state, value) {
        state.relationships.tenants = value
    }
}
