<template>

    <td :class="['table-cell', { 'highlight': highlight }, { 'hidden-mobile': hiddenOnMobile}]" :colspan="colspan" :style="customStyle" @click="$emit('click', $event)">

        <template v-if="type === 'number'">{{ data }}</template>

        <status-label v-else-if="type === 'amount'" :label="(data !== 0 && data) ? $n(data / 100, 'currency') : '0 €'" :type="data < 0 ? 'error' : 'success'" />

        <status-label v-else-if="type === 'ht_amount'" :label="(data !== 0 && data) ? `${$n(data / 100, 'currency')} HT` : '0 €'" :type="data < 0 ? 'error' : 'success'" />

        <status-label v-else-if="type === 'ttc_amount'" :label="(data !== 0 && data) ? `${$n(data / 100, 'currency')} TTC` : '0 €'" :type="data < 0 ? 'error' : 'success'" />

        <template v-else-if="type === 'date'">{{ data ? this.$moment(data).format('DD/MM/YYYY') : '-' }}</template>

        <status-label v-else-if="type === 'status'" :label="data.label" :type="data.type" />

        <tenant-list v-else-if="type === 'tenant-list'" :tenantIds="data" :propTenants="model" :collapsed="collapsed" @expandTenants="expandLines" />

        <landlord v-else-if="type === 'landlord'" :landlordId="data" :propLandlord="model" />

        <accounting-entries v-else-if="type === 'accounting-entries'" :accountingEntriesIds="data" :collapsed="collapsed" @expandAccountingEntries="expandLines" />

        <bank-account-transaction-reconciliation v-else-if="type === 'bank-account-transaction-reconciliation'" :bankAccountTransaction="data" :collapsed="reconciliationcollapsed" @expandAccountingEntries="expandLines" />

        <accounting-journal v-else-if="type === 'accounting-journal'" :accountingJournalId="data" />

        <accounting-account v-else-if="type === 'accounting-account'" :accountingAccountId="data" />

        <payment-details v-else-if="type === 'payment-details'" :paymentAttempts="data" :collapsed="collapsed" @expandPayments="expandLines" />

        <supplier v-else-if="type === 'supplier'" :supplierId="data.id" :propSupplier="model" :supplierType="data.type" />

        <status-tag v-else-if="type === 'tag'" :type="data.type" :icon="data.type">{{ data.label }}</status-tag>

        <progression v-else-if="type === 'progression'" :label="data.label" :steps="data.steps" />

        <inventory v-else-if="type === 'inventory'" :date="data.date" :inventory="data.inventory" />

        <duration v-else-if="type === 'duration'" :duration="data" />

        <signature v-else-if="type === 'signature'" :fileId="data" />

        <property-address v-else-if="type === 'property-address'" :address="data" />

        <property v-else-if="type === 'property'" :propertyId="data" />

        <download-viewer v-else-if="type === 'download-viewer'" :label="data.label" :redirectPath="data.path" :target="data.target" :fileId="data.fileId" :fileName="data.fileName" :openExternalLink="data.openExternalLink" />

        <button-table-cell v-else-if="type === 'button'" :target="data.target" :label="data.label" :fileId="data.fileId" :fileName="data.fileName" :openExternalLink="data.openExternalLink" :action="data.action" />

        <bank-number v-else-if="type === 'bank-number'" :last4Digits="data" />

        <document v-else-if="type === 'viewer'" :label="data.label" :redirectPath="data.path" :customStyle="customStyle" />

        <document-item v-else-if="type === 'document-item'" :label="data.label" :description="data.description" :icon="data.icon" :status="data.status" :badge="data.badge" />

        <contract v-else-if="type === 'contract'" :label="data.label" :imgPath="data.img" />

        <bank-name v-else-if="type === 'bank-name'" :bankAccountId="data" />

        <bank-account-transaction v-else-if="type === 'bank-account-transaction'" :line1="data.line1" :line2="data.line2" :icon="data.icon" :tag="data.tag" />

        <multi-lines v-else-if="type === 'string' && Array.isArray(data)" :lines="data" />

        <energy-diagnoses v-else-if="type === 'energy-diagnoses'" :label="data.label" :type="data.type" />

        <validation-status v-else-if="type === 'validation-status'" :active="data" />

        <clipboard-text v-else-if="type === 'clipboard-text'" :value="data" />

        <template v-else-if="type === 'text'">{{ data }}</template>

        <span class="empty-line" v-else-if="type === 'string' && !data">{{ '-' }}</span>

        <span v-html="data" v-else></span>

    </td>

</template>

<script>
    export default {
        props: {
            data: {
                required: true
            },
            model: {
                required: false
            },
            type: {
                type: String,
                default: 'string'
            },
            highlight: {
                type: Boolean,
                default: false
            },
            hiddenOnMobile: {
                type: Boolean,
                default: false
            },
            collapsed: {
                type: Boolean,
                default: true
            },
            reconciliationcollapsed: {
                type: Boolean,
                default: true
            },
            colspan: {
                type: Number,
                default: 1
            },
            customStyle: {
                type: Object,
                required: false
            }
        },

        methods: {
            expandLines(lines, type) {
                this.$emit('expandLines', lines, type)
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "status_tag": {
                "pending": "En attente",
                "paid": "Payé"
            }
        }
    }
</i18n>

<style lang="scss">
    $grey: #8F94A0;
    $red: #E43F3F;

    .table-cell, .table-cell > * {
        color: $grey;

        &.highlight,
        &.highlight > * {
            color: inherit;
        }

        &.hidden-mobile {
            @include desktop {
                display: none;
            }
        }

        .empty-line {
            color: inherit;
            font-size: inherit;
            opacity: .3;
        }

        .spinner-loader {
            justify-content: flex-start;
        }
    }
</style>
