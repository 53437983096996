<template>

    <div class="widget margin-widget">

        <div :class="['title-group', { 'with-slot-options': !!$slots['widget-options'] }]" v-if="tabs || title">

            <div class="widget-title">

                <div class="logo" v-if="logoImgUrl">

                    <img class="logo-url" :src="logoImgUrl" />

                </div>

                <h3 class="title" v-html="title" v-if="title" />

                <status-tag class="notif" type="notification" v-if="notification">{{ notification }}</status-tag>

                <status-tag class="notif" type="warning" v-else-if="warning">{{ warning }}</status-tag>

                <div class="button-group">

                    <button-input :class="['button', action.class, { 'background-success grey-darker-text-color' : !action.class }]" :text="action.title" :iconImg="action.icon" :dropDownOptions="action.options" @click="action.function.call()" iconColor="#393939" v-for="(action, key) in actions" :key="key" />

                </div>

            </div>

            <slot name="widget-options"></slot>

        </div>

        <div class="widget-filters" v-if="tabs">

            <hr class="widget-separator">

            <div class="content">

                <ul class="tabs">

                    <li :class="['item', { 'active': tab.active }]" v-for="(tab, key) in tabs" :key="key" @click="updateTab(tab)">

                        <p>{{ tab.title }}</p>

                    </li>

                </ul>

                <table-filters class="table-filters" v-if="isFilters" :top="155" :left="585">

                    <div class="reset">

                        <p>{{ $t('filter') }}</p>

                        <button-input class="button background-transparent green-text-color button-input" :text="$t('reset')" :noPadding="true" @click="resetFilters" />

                    </div>

                    <hr class="reset-separator">

                    <radio-input name="paid-filter" v-model="paidFilter" :options="paidFilterOptions" :rules="'required'" />

                </table-filters>

            </div>

        </div>

        <div class="content">

            <spinner-loader v-if="!isLoaded" />

            <form-info type="error" :text="error" v-else-if="error" />

            <div class="empty-resources" v-else-if="isEmpty">

                <svg-icon class="icon" width="32" height="32" :viewbox="'0 0 12 16'"><document-icon /></svg-icon>

                <p class="text" v-html="$t('empty_data')"></p>

            </div>

            <div class="layout-row inner" v-show="isLoaded">

                <div class="layout-col">

                    <slot />

                </div>

                <div class="layout-col col-2 illu" v-if="background">

                    <img-sprite :path="background" :width="backgroundIconWidth * backgroundIconDpi / 100" :height="backgroundIconHeight * backgroundIconDpi / 100" />

                </div>

            </div>

        </div>

    </div>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import DocumentIcon from '~/components/icons/empty-resource-icons/document-icon'
    import ImgSprite from '~/components/img-sprite'
    import SpinnerLoader from '~/components/spinner-loader'
    import StatusTag from '~/components/status-tag'
    import FormInfo from '~/components/form-info'
    import TableFilters from '~/components/table-list/table-filters'

    export default {
        components: {
            SvgIcon,
            DocumentIcon,
            SpinnerLoader,
            ImgSprite,
            StatusTag,
            FormInfo,
            TableFilters
        },

        props: {
            title: {
                type: String,
                required: false
            },
            search: {
                type: String,
                required: false
            },
            defaultEmpty: {
                type: Boolean,
                default: false
            },
            fetch: {
                type: Function,
                default() {
                    return new Function
                }
            },
            notification: {
                type: [Number, String],
                required: false
            },
            warning: {
                type: [Number, String],
                required: false
            },
            background: {
                type: String,
                required: false
            },
            actions: {
                type: Array,
                required: false
            },
            tabs: {
                type: Array,
                required: false
            },
            isFilters: {
                type: Boolean,
                default: false
            },
            logoImgUrl: {
                type: String,
                required: false
            }
        },

        async fetch() {
            try {

                this.isLoaded = false

                await this.fetch.call()

            } catch(error) {

                this.error = error

                throw error

            } finally {

                this.isLoaded = true
            }
        },

        data() {
            return {
                isLoaded: false,
                isEmpty: this.defaultEmpty,
                backgroundIconWidth: 134,
                backgroundIconHeight: 113,
                backgroundIconDpi: 300,
                error: '',
                paidFilter: '',
                paidFilterOptions: [
                    {
                        text: this.$t('paid'),
                        value: 'paid'
                    },
                    {
                        text: this.$t('unpaid'),
                        value: 'unpaid'
                    }
                ]

            }
        },

        methods: {
            async updateTab(tab) {

                this.$emit('activeTab', tab.name)

                await this.$fetch()
            },

            async updateFilter(filter) {

                this.$emit('activeFilter', filter)

                await this.$fetch()
            },

            resetFilters() {
                this.paidFilter = ''
            }
        },

        watch: {
            paidFilter(newVal) {
                this.updateFilter(newVal)
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "empty_data": "Il n'y a aucune données à afficher pour le moment car la liste est vide.",
            "paid": "Payé",
            "unpaid": "Impayé",
            "filter": "Filtrer",
            "reset": "Réinitialiser"
        }
    }
</i18n>

<style lang="scss" scoped>
    $separator-color: #F2F2F2;
    $green-color: #BED000;
    $grey: #525254;
    $dark-grey: #393939;

    .widget {
        background: white;
        box-shadow: 0px 7px 9px rgba(141, 141, 141, 0.1), 0px 4px 13px rgba(141, 141, 141, 0.08);
        border-radius: 10px;
        padding: 28px 25px;
        position: relative;

        &.background-red {
            background: #FCCFCF;
        }

        &.background-payment-method {
            border-radius: 12px;
            background: linear-gradient(180deg, rgba(190, 208, 0, 1) 0%, rgba(98, 170, 71, 0.7) 100%), url("~/static/img/widget/payment-method-background343x178.jpeg");
            background-size: 100% 100%;
            background-position: 100% 60%;
        }

        &.background-bulk-receipt {
            background: linear-gradient(275deg, rgba(98, 170, 71, 0.422) 4.22%, rgba(190, 208, 0, 0.9679) 96.79%), url('~/static/img/widget/key320x171.png') no-repeat, #62AA47;
            background-size: contain;
            background-position: 133% 20px;
        }

        &.background-oskar-courses-1 {
            background: linear-gradient(270deg, rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 0.70)), no-repeat center right / contain url("~/static/img/widget/oskar-courses-1.jpg1008x144.jpeg"), #f0F4F3;
        }

        &.background-incoming-features {
            background: url("~/static/img/widget/incoming-features.svg");
            background-size: 100% 100%;
        }

        &.background-guide-oskar {
            background: linear-gradient(275deg, rgba(98, 170, 71, 0.822), rgba(190, 208, 0, 0.779)), url("~/static/img/widget/guide-background611x496.jpeg");
            background-color: #62AA47;
            background-size: 120% 120%;
        }

        &.swan-card-background {
            background: url("~/static/img/widget/swan-card-background380x253.png"), lightgray 79.903px -7.067px / 102.105% 122.426% no-repeat;
            background-color: #333;
            background-size: 100% auto;
            background-position: 90px 20px;

            .title {
                color: white;
            }
        }

        &.background-no-rental {
            background: linear-gradient(90deg, #FFF 10%, rgba(255, 255, 255, 0.00) 60%), url("~/static/img/widget/no-rental-illustration757x346.jpeg") no-repeat, white;
            background-size: cover;
            background-position: 300px;
        }

        &.background-rental-signature {
            background: linear-gradient(90deg, #FFF 60%, rgba(255, 255, 255, 0.00) 100%), url("~/static/img/widget/rental-signed-illustration780x270.jpeg") right / contain no-repeat, white;
        }

        &.background-property-manager-infos {
            background: url("~/static/img/widget/property-manager-infos-background380x274.jpeg") no-repeat right center, white;
            background-size: cover;
            background-blend-mode: multiply, normal;
            box-sizing: border-box;
        }

        &.background-welcome-card-tenant {
            background: linear-gradient(275deg, #62AA47 4.22%, #BED000 96.79%), url("~/static/img/widget/oskar-welcome-card-tenant-background780x252.jpeg") lightgray 50% / cover no-repeat;
            background-blend-mode: multiply, normal;

            .title {
                color: white;
                font-size: 24pt !important;
                margin-top: 32px;
            }
        }

        &.background-welcome-card-landlord {
            background: linear-gradient(275deg, #62AA47 4.22%, #BED000 96.79%), url("~/static/img/widget/oskar-welcome-card-landlord-background780x252.jpeg") lightgray 50% / cover no-repeat;
            background-blend-mode: multiply, normal;
            padding: 54px 25px;;

            .title {
                color: white;
                font-size: 24pt !important;
                margin-top: 32px;
                width: 50%;
            }
        }

        &.border-green-gradient {
            border-bottom: 8px solid #BED000 ;

        }

        .title-group {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            flex-direction: row;
            margin-bottom: 25px;

            .widget-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex: 4;

                .logo {
                    max-width: 64px;
                    height: 64px;
                    border-radius: 60%;
                    box-shadow: 0px 7px 16px 0px rgba(141, 141, 141, 0.28), 0px 4px 20px 0px rgba(141, 141, 141, 0.15);
                    margin-right: 16px;
                    .logo-url {
                        width: 64px;
                        height: 64px;
                        border-radius: 60%;
                        object-fit: cover;
                    }
                }

                .title {
                    font-size: 18pt;
                    font-weight: 600;
                }

                .notif {
                    margin-left: 12px;
                }

                .button-group {
                    display: flex;
                    flex-direction: row;
                    margin-left: 22px;

                    .button{
                        min-height: 38px;
                        margin-left: 8px;

                        .text{
                            &.icon-offset {
                                margin-right: 6px;
                            }
                        }
                    }
                }
            }

            &.with-slot-options {
                .widget-title {
                    justify-content: initial;
                }

                .select-box-input::v-deep .input {
                    height: 32px;
                    width: 180px;
                    font-size: 9.5pt;
                    border-radius: 4px;
                }
            }
        }

        &::v-deep .widget-section-title {
            color: #9B9B9B;
            font-size: 8.5pt;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 15px;
        }

        .widget-filters{

            margin-bottom: 32px;

            .widget-separator {
                height: 1px;
                align-self: stretch;
                background: #F2F2F2;
                border: none;
                margin-bottom: 24px;
            }

            .content{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .tabs {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .item {
                        height: 32px;
                        display: flex;
                        padding: 1px 16px;
                        justify-content: center;
                        align-items: center;
                        color: $grey;
                        gap: 4px;
                        border-radius: 6px;
                        background:  #EFEFEF;
                        cursor: pointer;
                        white-space: nowrap;

                        p {
                            font-size: 8pt;
                            font-style: normal;
                            font-weight: 700;
                            text-transform: uppercase;
                            text-align: center;
                            color: inherit;
                        }

                        & ~ .item {
                            margin-left: 12px;
                        }

                        &.active {
                            color: #FFF;
                            background: #525254;

                        }
                    }
                }

                .table-filters {

                    .reset-separator {
                        height: 1px;
                        align-self: stretch;
                        background: #F2F2F2;
                        border: none;
                        margin: 16px 0;
                    }

                    .reset{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        align-self: stretch;

                        p {
                            color: $dark-grey;
                            font-size: 12pt;
                            font-style: normal;
                            font-weight: 600;
                        }
                        &::v-deep .button-input {
                            padding: 0;
                            min-height: 0;
                        }
                    }

                    &::v-deep .radio-input {
                        .option-list {
                            flex-direction: column;
                            gap: 16px;
                            width: 160px;

                            .option {
                                flex-basis: 0;

                                .checkmark {
                                    height: 16px;
                                    width: 16px;
                                    border-radius: 4px;
                                    border: 1px solid #6C6C6C;
                                    margin-right: 8px;

                                    &.checked{
                                        border-color: #BED000;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .content {
            display: flex;
            flex-direction: column;

            .inner {
                flex: 1;
            }

            .illu {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 25px;
            }
        }

        &::v-deep .empty-resources {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 60px 0;

            .icon {
                margin-bottom: 25px;
            }

            .text {
                color: #8F94A0;
            }
        }

        .section {
            & ~ .section {
                margin-top: 20px;
                padding-top: 20px;
                border-top: 1px solid $separator-color;
            }

            & .subtitle{
                font-weight: 600;
            }
        }

        .block {
            margin-top: 24px;
        }

        @media only screen and (max-width: $mobile-breakpoint) {
            .filters {
               width: 35%;
            }
        }
    }
</style>
