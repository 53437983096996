<template>

    <div class="button-table-cell">

        <button-input class="background-phantom green-text-color" :text="label" :iconImg="'download'" :action="action" :fileId="fileId" :redirectExternalLink="target" :openExternalLink="openExternalLink" :noPadding="false" :downloadFileName="getFileName" v-if="fileId || target || openExternalLink" />

        <span class="empty" v-else>-</span>

    </div>

</template>

<script>
    export default {
        props: {
            fileId: {
                type: [Number, String],
                required: false
            },
            fileName: {
                type: String,
                required: false
            },
            action: {
                type: Function,
                required: false
            },
            label: {
                type: String,
                required: false
            },
            openExternalLink: {
                type: String,
                required: false
            },
            target: {
                type: String,
                required: false
            }
        },

        computed: {
            getFileName() {
                if (this.fileName === null || this.fileName === undefined) return this.label

                return this.fileName
            }
        }
    }
</script>
